.article {
  display: grid;
  grid-template-rows: 25vh 25vh 25vh 25vh;
  grid-template-columns: 25vh 25vh 25vh 25vh;
  grid-gap: 3px;
  align-items: center;
  justify-content: center;
  color: #424c56;

}

.article-policys {
  display: grid;
  /* grid-template-rows: 100vh 25vh 25vh 25vh; */
  grid-template-columns: 12vh 25vh 25vh 25vh;
  grid-gap: 3px;
  align-items: center;
  justify-content: center;
  color: #424c56;
}
 
.article-personpolicys {
  display: grid;
  /* grid-template-rows: 100vh 100vh 100vh 25vh; */
  grid-template-columns: 12vh 25vh 25vh 25vh;
  grid-gap: 3px;
  align-items: center;
  justify-content: center;
  color: #424c56;
}

#root {
/* background-color: lightyellow; */
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid black; */
  font-size: 3em;
}

.wrapper-policys {
/* display: flex; */
grid-row: 1/-1;
grid-column: 1/-1;


}

.before-login{
  display: flex;
  text-align: center;
margin-bottom: 2em;
transition: 1s;
}

.before-login:hover{
  color:#17a2b8
}

.wrapper-header {
  display: flex;
  border-width: 2px;
  /* border: solid; */
  padding: 1em;
  border-radius: 10px;
  background-color: white;
  grid-row: 1;
  grid-column: 2/span 2;
  justify-content: center;
  transition: 1s;

}

.wrapper-header-text{
  /* display:flex; */
  font-size: 1.5em;
  text-align: center;
  width: -webkit-fill-available;


}

.wrapper-header:hover{
  /* display:flex; */
  color:#17a2b8


}

.wrapper-login-text{
  display: flex;
  font-size: 1.5em;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  padding-bottom: 1em;
}

.wrapper-login{
  /* display: flex; */
  border-width: 2px;
  /* border: solid; */
  padding: 2em;
  border-radius: 10px;
  box-shadow: 7px 9px 28px 2px rgba(148,148,148,0.43);
  background-color: white;
}

.section-login {
  display: flex;
  grid-column: 2/span 2;
  grid-row: 1/span 4;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  align-self: center;
  justify-self: center;
}

.form-label {
  /* color:red; */

  color:#80868c;
  font-size: 0.8em;
}

/* .form-control{
  color:red;
  font-size: 0.8em;
} */

.button-login-signup {
padding-top:1em;
padding-bottom:1em;}

.button-smlr {
display:flex;  
}

.signup-checkbox {
  color:#80868c;
  font-size: 0.8em;
}
.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.button-signout{
  padding-left: 1em;
}

.modal-backdrop.show{
  cursor: auto;
}
.custom-dialog {
  width:40%;
   max-width: 40%!important;
   background-color: transparent;
   cursor: auto;
  }

  .custom-dialog .modal-content {
    background-color: transparent;
    border-width: 0px;
  }

.drag-box { 
 cursor:auto;
}
.modal-header{
  cursor: move;
}
.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.specialLink:hover {
  cursor: pointer;
}

xmark-container {
  /* width: 100vw; */
  height: 50vw;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.row { 
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-bottom: 0.5em;
}

.rowNew:hover { 
background-color: whitesmoke;
}

.rowDescText {
  font-size: 0.85em;
  margin-bottom: 0em;
  margin-top: 0em;
}

.form-group{ 
  margin-bottom: 0em;
}

.form-check {
  margin-bottom: 2em;
}